.filtersBtn {
  margin: 7px 5px 0 0;
  button {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.25);
  }
}
select {
  padding: 10px;
  width: 100%;
  background: none;
  color: #565656;
}
.perksContainer {
  margin: 0 auto;
}
